import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import MainPage from '@/views/MainPage.vue'
import LoginPage from '@/views/LoginPage.vue'
import NoticePage from '@/views/NoticePage.vue'
import NoticePageb from '@/views/NoticePageb.vue'
import ServiceComp from '@/views/ServiceComp.vue'
import ProfessMsg from '@/views/ProfessMsg.vue'
import AboutUs from '@/views/AboutUs.vue'
import MsgShare from '@/views/MsgShare.vue'
import ArticleDetail from '@/views/ArticleDetail.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main',
    component: MainPage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/notice',
    name: 'notice',
    component: NoticePage
  },
  {
    path:'/noticeb',
    name:'noticeb',
    component: NoticePageb
  },
  {
    path:'/service',
    name:'service',
    component: ServiceComp
  },
  {
    path:'/profess',
    name:'profess',
    component: ProfessMsg
  },
  {
    path:'/us',
    name:'us',
    component: AboutUs
  },
  {
    path:'/share',
    name:'share',
    component: MsgShare
  },
  {
    path:'/detail',
    name:'detail',
    component: ArticleDetail
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token_'); // 假设 token 存储在 localStorage 中
  if (to.name === 'login_' && token) {
    // 如果用户已经登录，重定向到首页
    next({ name: 'main' });
  } else {
    // 否则，允许导航
    next();
  }
});

export default router
