<template>
    <nav class="navbar" :class="{ 'navbar-fixed': isFixed, 'navbar-colored': isColored }">
        <div class="main-nav">
            <div class="left-menu">
                <div v-if="icon_num == false"
                    style="width: 20vw;display: flex;justify-content: space-between;font-size: 29px;font-weight: bold;margin-right: 10px;">
                    <img src="@/assets/icon.png">亚信</div>
                <div v-else-if="icon_num == true"
                    style="width: 20vw;display: flex;justify-content: space-between;font-size: 29px;font-weight: bold;margin-right: 10px;">
                    <img src="@/assets/icon1.png">亚信</div>
                <div @click="router.push('/')">首页</div>
                <div @click="router.push('/service')">服务项目</div>
                <div @click="router.push('/profess')">行业资讯</div>
                <div @click="router.push('/share')">案例分享</div>
                <div @click="router.push('/us')">关于我们</div>
            </div>
            <div class="right-menu">
                <div @click="onSearch"><el-icon>
                        <Search />
                    </el-icon></div>
                <div @click="toLogin"><el-icon>
                        <Bell />
                    </el-icon></div>
                <div v-if="isLogin == false" @click="toLogin"><el-icon>
                        <User />
                    </el-icon></div>
                <div @mouseenter="ShowPop" @mouseleave="hideShowPop" style="font-size: 14px;margin-left: 10px;position: relative;" v-else-if="isLogin == true">{{ name }}
                    <div v-if="isShowPop==true" :class="{'pop_color':isColored}"  style="width: 5vw;height: 10vh;background-color: white;position: absolute;bottom: -10vh;font-size: 14px;color: black;overflow: hidden;">
                        <div style="width: 5vw;height: 5vh;border-bottom: 1px solid black;display: flex;justify-content: center;align-items: center;position: absolute;top: 0;">个人中心</div>
                        <div @click="logout" style="width: 5vw;height: 5vh;justify-content: center;align-items: center;position: absolute;bottom: 0;">注销</div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script setup>
import router from '@/router';
import { defineProps,ref } from 'vue';
import { defineEmits } from 'vue';
const props = defineProps({
    isFixed: Boolean,
    isColored: Boolean,
    icon_num: Boolean,
    isLogin: Boolean,
    name: String
})
const emit = defineEmits(['search'])
const onSearch = () => {
    emit('search')
}
const toLogin = () => {
    router.push('/login')
}

const ShowPop = ()=>{
    isShowPop.value = true;
    console.log(isShowPop.value)
}

const hideShowPop = ()=>{
    isShowPop.value = false;
}

const logout = ()=>{
    const token = localStorage.getItem('token')
    if(token){
        localStorage.removeItem('token')
        location.reload()
    }
}

let isShowPop = ref(false);

</script>
<style scoped>
.navbar {
    height: 10vh;
    width: 100%;
    position: relative;
    z-index: 1000;
    background-color: transparent;
    /* 初始背景透明 */
    transition: background-color 0.5s;
    color: #fff;
}

.main-nav {
    width: 85vw;
    height: 10vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}

.left-menu {
    width: 40vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-menu div {
    /* min-width: 2vw;
    max-width: 10vw; */
    width: 22vw;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.left-menu div:hover {
    background-color: rgba(128, 128, 128, 0.3);
    cursor: grab;
}

.right-menu {
    width: 10vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 26px;
}

.right-menu div {
    max-width: 10vw;
    min-width: 2vw;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-menu div:hover {
    cursor: grab;
}

.navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
}

.navbar-colored {
    background-color: #fff;
    /* 滚动后背景颜色 */
    color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* 添加阴影以突出导航栏 */
}
.pop_color{
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    color: black !important;
}
</style>