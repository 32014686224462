<template>
    <div class="all">
        <SearchComp @close="onClose" v-if="search_type==1" style="z-index: 9999;position: fixed;top: 0;left: 0;"></SearchComp>
        <NavComp @search="onSearch" :isFixed="isFixed_" :isColored="isColored_" :icon_num="icon_num_" :isLogin="isLogin_" :name="name_"></NavComp>
        <div class="head-img">
            <img src="@/assets/us.png" width="100%" height="100%">
            <div class="img-text">专业铸就品质，诚信赢得未来。</div>
        </div>
        <div class="msg-1">
            <div style="color: black;font-size: 1.8vw;font-weight: bold;margin-bottom: 8vh;">
                文化与价值观
            </div>
            <ul style="padding: 0 !important;">
                <li class="list-item">
                    <strong>专业至上:</strong>
                    <span>税务服务是一项高度专业的工作，需要扎实的税务知识、丰富的实践经验以及敏锐的洞察力。我们致力于为客户提供最专业的税务解决方案，不断追求卓越，以高标准要求自己。</span>
                </li>
                <li class="list-item">
                    <strong>诚信为本:</strong>
                    <span>诚信是税务服务行业的基石。我们严格遵守税法法规，坚守职业道德，以诚信为本，为客户创造合法合规的价值。</span>
                </li>
                <li class="list-item">
                    <strong>与时俱进:</strong>
                    <span>税务环境不断变化，只有不断创新才能保持竞争力。我们鼓励创新思维，积极探索新的服务模式和技术应用，以适应市场变化。</span>
                </li>
                <li class="list-item">
                    <strong>勇于担当:</strong>
                    <span>税务服务行业充满挑战，我们倡导积极进取的精神，勇于承担责任，为客户排忧解难。</span>
                </li>
            </ul>
        </div>
        <div class="msg-2">
            <div style="color: black;font-size: 1.8vw;font-weight: bold;margin-bottom: 8vh;">
                公司介绍
            </div>
            <div style="width: 70%;font-size: 20px;line-height: 1.5;">&nbsp;&nbsp;&nbsp;&nbsp;玉溪亚信税务师事务所有限公司位于云南省玉溪市，是经国家税务总局云南省税务局正式批准成立的提供全方位涉税服务的专业机构。公司前身：玉溪新源税务师事务所，于2011年3月创立。经过多年的发展与沉淀，我们已成长为玉溪地区极具影响力和专业实力的税务服务机构。</div>
            <div style="width: 70%;font-size: 20px;line-height: 1.5">&nbsp;&nbsp;&nbsp;&nbsp;公司拥有一支高素质、经验丰富的专业团队，成员均具备扎实的税务知识和丰富的实践经验。他们不仅精通国家税收法律法规，还能够敏锐地捕捉行业动态和政策变化，为客户提供精准、高效、个性化的涉税解决方案。我们的服务范围广泛，涵盖税务咨询、税务筹划、税务审计、税务代理、税收筹划等多个领域。无论是企业日常税务申报，还是复杂的税务筹划项目，我们都能凭借专业的知识和严谨的态度，帮助客户合理降低税务成本，有效规避税务风险，确保税务合规。</div>
        </div>
        <div class="msg-3">
            <div style="color: black;font-size: 1.8vw;font-weight: bold;margin-bottom: 8vh;">
                领导团队
            </div>
            <div class="pereson-list">
                <div class="card" v-for="(item,index) in leaderData" :key="index">
                    <el-image style="width: 100%; height: 95%" :src="'https://api2.yaxinshuiwu.com/imagel/'+item.id" fit="cover" />
                    <div style="margin-top: 3%;font-size: 1.5vw;font-weight: 500;">{{ item.name }}</div>
                    <div style="margin-top: 3%;font-size: 0.8vw;">{{ item.role }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import NavComp from '@/components/NavComp.vue'
import SearchComp from '@/components/SearchComp.vue';
import { defineComponent,onMounted,onBeforeUnmount } from 'vue';
import { ref } from 'vue';
import { getLeader, getLoginMsg } from '@/api/login';
defineComponent({
    components: { NavComp,SearchComp }
})
let isFixed_ = ref(false)
let isColored_ = ref(false)
let icon_num_ = ref(false)
let search_type = ref(0)
let isLogin_ = ref(false)
let name_ = ref('')
let leaderData = ref([])

const handleScroll_ = () => {
    if(search_type.value==1){
        search_type.value = 0;
    }
    const scrollTop = event.target.scrollTop;
    console.log(scrollTop)
    isFixed_.value = scrollTop > 0; // 当页面滚动时，导航栏固定
    isColored_.value = scrollTop > 150; // 当滚动超过150px时，导航栏变色
    if (isColored_.value == true) {
        icon_num_.value = true
    }
    else {
        icon_num_.value = false
    }
}
const onSearch = ()=>{
    search_type.value = 1;
}
const onClose = ()=> {
    search_type.value = 0;
}
onMounted(async() => {
    const token = localStorage.getItem('token')
    await getLoginMsg(token)
    const allElement = document.querySelector('.all');
    allElement.addEventListener('scroll', handleScroll_);
    leaderData = await getLeader()
})
onBeforeUnmount(() => {
    const allElement = document.querySelector('.all');
    allElement.removeEventListener('scroll', handleScroll_)
})
</script>
<style scoped>
    .all {
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        position: relative;
    }

    .head-img {
        width: 100%;
        height: 65%;
        position: absolute;
        top: 0;
    }

    .msg-1 {
        width: 100%;
        height: 60%;
        background-color: #f4f4f4;
        position: absolute;
        top: 75%;
        padding: 6vh 0 5vh 7vw;
    }

    .img-text{
        width: 50%;
        height: 15%;
        font-size: 2vw;
        position: absolute;
        bottom: 10%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }

    .list-item {
        margin-bottom: 5vh;
    }

    .msg-2 {
        width: 100%;
        height: 60%;
        background-color: white;
        position: absolute;
        top: 145%;
        padding: 6vh 0 5vh 7vw;
    }

    .msg-3 {
        width: 100%;
        min-height: 60%;
        background-color: #f4f4f4;
        position: absolute;
        top: 215%;
        padding: 6vh 7vw 5vh 7vw;
    }

    .person-list {
        width: 80vw;
        min-height: 40%;
        display: flex;
        justify-content: space-around;
    }

    .card {
        width: 15vw;
        height: 30vh;
    }
</style>