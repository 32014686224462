<template>
    <div class="all">
        <SearchComp @close="onClose" v-if="search_type == 1" style="z-index: 9999;position: fixed;top: 0;left: 0;">
        </SearchComp>
        <NavComp @search="onSearch" :isFixed="isFixed_" :isColored="isColored_" :icon_num="icon_num_"
            :isLogin="isLogin_" :name="name_"></NavComp>
        <div class="top-img">
            <img width="100%" height="auto" src="@/assets/service.png">
        </div>
        <div class="content">
            <div class="left-panel">
                <el-menu @select="change" default-active="1" class="el-menu-vertical-demo" background-color="#545c64"
                    active-text-color="#ffd04b" text-color="#fff">
                    <el-menu-item v-for="(item,index) in serviceData" :key="index" :index="String(index + 1)">
                        <el-icon>
                            <document />
                        </el-icon>
                        <span>{{ item.title }}</span>
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="right-panel" v-html="current_content">
                
            </div>
        </div>
    </div>
</template>
<script setup>
import { getLoginMsg, getMainNews } from '@/api/login';
import NavComp from '@/components/NavComp.vue';
import SearchComp from '@/components/SearchComp.vue';
import { defineComponent, ref, onMounted } from 'vue';
defineComponent({
    components: { NavComp, SearchComp }
})
let isFixed_ = ref(true)
let isColored_ = ref(true)
let icon_num_ = ref(true)
let isLogin_ = ref(false)
let name_ = ref('')
let search_type = ref(0)
let serviceData = ref([])
let current_content = ref('')
onMounted(async () => {
    const token = localStorage.getItem('token')
    await getLoginMsg(token)
    serviceData.value = await getMainNews(2,1)
    current_content.value = serviceData.value[0].content
})
const onSearch = () => {
    search_type.value = 1;
}
const onClose = () => {
    search_type.value = 0;
}

const change = (index_) => {
    console.log(index_)
    current_content.value = serviceData.value[index_].content
}
</script>
<style scoped>
.all {
    width: 100vw;
    height: 100vh;
    background-color: rgb(221.7, 222.6, 224.4);
    overflow-y: scroll;
}

.top-img {
    width: 100%;
    height: 400px;
    background-color: rgb(221.7, 222.6, 224.4);
}

.content {
    width: 100%;
    height: 90vh;
    padding-left: 10vw;
    padding-right: 10vw;
    margin-top: 50px;
    overflow: hidden;
    background-color: rgb(221.7, 222.6, 224.4);
}

.left-panel {
    display: inline-block;
    width: 15vw;
    height: 90vh;
    overflow-y: scroll;
    background-color: #545c64
}

.right-panel {
    display: inline-block;
    width: 60vw;
    height: 89vh;
    background: #fff;
    border-radius: 2px 2px 0 0;
    overflow-y: scroll;
    font-size: 16px;
    line-height: 1.6;
    white-space: pre-wrap;
    padding: 1vh 1vw 0 1vw;
}
</style>