import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icon.png'
import _imports_1 from '@/assets/wechat.png'
import _imports_2 from '@/assets/weibo.png'
import _imports_3 from '@/assets/douyin.png'
import _imports_4 from '@/assets/bili.png'
import _imports_5 from '@/assets/ga.png'


const _hoisted_1 = { class: "bottom" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "nav" }
const _hoisted_4 = { class: "left-nav" }

import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'BottomNav',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("div", null, [
            _createElementVNode("img", {
              width: "22px",
              height: "22px",
              src: _imports_0
            })
          ], -1)),
          _createElementVNode("div", {
            style: {"cursor":"grab"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/')))
          }, "关于我们"),
          _createElementVNode("div", {
            style: {"cursor":"grab"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/service')))
          }, "联系我们"),
          _createElementVNode("div", {
            style: {"cursor":"grab"},
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/profess')))
          }, "人才招聘"),
          _createElementVNode("div", {
            style: {"cursor":"grab"},
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/share')))
          }, "新闻动态"),
          _createElementVNode("div", {
            style: {"cursor":"grab"},
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(router).push('/us')))
          }, "资讯分享")
        ]),
        _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"right-nav\" data-v-5fbd1a36><div data-v-5fbd1a36><img width=\"16px\" height=\"16px\" src=\"" + _imports_1 + "\" data-v-5fbd1a36></div><div data-v-5fbd1a36><img width=\"16px\" height=\"16px\" src=\"" + _imports_2 + "\" data-v-5fbd1a36></div><div data-v-5fbd1a36><img width=\"16px\" height=\"16px\" src=\"" + _imports_3 + "\" data-v-5fbd1a36></div><div data-v-5fbd1a36><img width=\"16px\" height=\"16px\" src=\"" + _imports_4 + "\" data-v-5fbd1a36></div></div>", 1))
      ]),
      _cache[7] || (_cache[7] = _createStaticVNode("<div style=\"background-color:white;width:100%;height:0.5px;\" data-v-5fbd1a36></div><div class=\"footer-tip\" style=\"width:18%;height:20px;color:white;font-size:12px;margin-top:1vh;\" data-v-5fbd1a36> 隐私政策 <ul data-v-5fbd1a36><li data-v-5fbd1a36>使用条款</li><li data-v-5fbd1a36>问题反馈</li></ul></div><div class=\"copyright\" data-v-5fbd1a36>Copyright © 2025 YAXIN 玉溪亚信 版权所有</div><div style=\"display:inline-block;margin-right:1vw;\" data-v-5fbd1a36><a style=\"text-decoration:none;color:white;font-size:12px;\" href=\"https://beian.miit.gov.cn/#/Integrated/index\" data-v-5fbd1a36>滇ICP备2024032844号</a></div><div style=\"display:inline-block;position:relative;\" data-v-5fbd1a36><img style=\"position:absolute;bottom:0;left:0;\" width=\"20px\" height=\"20px\" src=\"" + _imports_5 + "\" data-v-5fbd1a36><a style=\"text-decoration:none;color:white;font-size:12px;margin-left:2vw;\" href=\"https://beian.mps.gov.cn/#/query/webSearch\" data-v-5fbd1a36>滇公网安备53040202000576号</a></div>", 5))
    ])
  ]))
}
}

})