<template>
  <div style="margin-left: 17%;margin-top: 1%;display: flex;align-items: center;cursor: grab;" @click="router.back()"><el-icon><ArrowLeftBold /></el-icon>返回</div>
    <div class="article-container">
      <!-- 文章头部信息 -->
      <div class="article-header">
        <h1 class="article-title">{{ article.title }}</h1>
        <div class="article-meta">
          <span class="author">作者：{{ article.author }}</span>
          <span class="publish-time">发布时间：{{ formatTime(article.update_time) }}</span>
          <span class="views">浏览量：{{ article.view_count }}</span>
        </div>
      </div>
  
      <!-- 文章内容 -->
      <div class="article-content" v-html="article.content"></div>
    </div>
  </template>
  
  <script setup>
  import router from "@/router";
import { ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
  let article = ref({})
  onBeforeMount(()=>{
    getData()
  })

  const formatTime = (time) => {
    const res = String(time).slice(0,10)
    return res
  }

  const getData = () => {
    const route = useRoute()
    const query = route.query
    if(Number(query.category_id)==1){
      const index_ = query.index
      article.value = JSON.parse(localStorage.getItem('a1'))[index_]
    }
    else if(Number(query.category_id)==2){
      const index_ = query.index
      article.value = JSON.parse(localStorage.getItem('a2'))[index_]
      console.log(article.value)
    }
    else if(Number(query.category_id)==3){
      const index_ = query.index
      article.value = JSON.parse(localStorage.getItem('a3'))[index_]
      console.log(article.value)
    }
    else if(Number(query.category_id)==-1){
      const index_ = query.index
      article.value = JSON.parse(localStorage.getItem('hot'))[index_]
      console.log(article.value)
    }
    else if(Number(query.category_id)==-2){
      const index_ = query.index
      article.value = JSON.parse(localStorage.getItem('search'))[index_]
      console.log(article.value)
    }
  }

  </script>
  
  <style scoped>
  .article-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .article-header { 
    margin-bottom: 20px;
  }
  
  .article-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  
  .article-meta {
    font-size: 14px;
    color: #666;
    display: flex;
    justify-content: center;
  }
  
  .article-meta span {
    margin-right: 15px;
  }
  
  .article-content {
    font-size: 16px;
    line-height: 1.6;
    white-space: pre-wrap;
  }
  </style>