<template>
    <div class="all">
        <SearchComp @close="onClose" v-if="search_type == 1" style="z-index: 9999;position: fixed;top: 0;left: 0;"></SearchComp>
        <NavComp @search="onSearch" :isFixed="isFixed_" :isColored="isColored_" :icon_num="icon_num_"
            :isLogin="isLogin_" :name="name_"></NavComp>
        <div class="content">
            <div class="left-panel">
                <div @click="goArticle(index)" class="news" v-for="(item, index) in newData" :key="index">
                    <div class="new-head">
                        <h3 style="font-size: 14px;line-height: 24px;">{{ item.title }}</h3>
                    </div>
                    <div class="new-content">
                        <p style="font-size: 14px;line-height: 20px;color: #777;" v-html="item.content.slice(0,20)"></p>
                    </div>
                    <div class="new-footer">
                        <p style="font-size: 14px;line-height: 20px;color: #777;">{{ item.update_time }}</p>
                    </div>
                </div>
            </div>
            <div class="right-panel">
                <div class="hot">
                    <h3 class="hot-title">热点资讯</h3>
                    <ul style="background-color: #f9f9f9;padding: 0 !important;margin: 0 !important;">
                        <li @click="goHot(index)" class="hot-item" v-for="(item, index) in hotData" :key="index">
                            <span class="num">{{ index + 1 }}</span>
                            <span class="hot-text">{{ item.title }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div style="width: 100%;display: flex;justify-content: center;height: 6vh;align-items: center;">
            <el-pagination @current-change="change_page" background layout="prev, pager, next" :total="total" />
        </div>
    </div>
</template>
<script setup>
import { getArtTotal, getHot, getLoginMsg, getMainNews } from '@/api/login';
import NavComp from '@/components/NavComp.vue';
import SearchComp from '@/components/SearchComp.vue';
import router from '@/router';
import { defineComponent, ref, onMounted } from 'vue';
defineComponent({
    components: { NavComp, SearchComp }
})
let isFixed_ = ref(true)
let isColored_ = ref(true)
let icon_num_ = ref(true)
let isLogin_ = ref(false)
let name_ = ref('')
let search_type = ref(0)
let hotData = ref({})
let newData = ref([])
let total = ref(1)
onMounted(async () => {
    const token = localStorage.getItem('token')
    await getLoginMsg(token)
    total.value = await getArtTotal(3)
    newData.value = await getMainNews(3,1)
    localStorage.setItem('a2',JSON.stringify(newData.value))
    hotData.value = await getHot()
    localStorage.setItem('hot',JSON.stringify(hotData.value))
})
const onSearch = () => {
    search_type.value = 1;
}
const onClose = () => {
    search_type.value = 0;
}

const goArticle = (index_) => {
    router.push({'name':'detail','query':{'category_id':2,'index':index_}})
}

const goHot = (index_) => {
    router.push({'name':'detail','query':{'category_id':-1,'index':index_}})
}

const change_page = async(index_) => {
    newData.value = await getMainNews(3,index_)
}
</script>
<style scoped>
.all {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
}

.content {
    overflow: hidden;
    background: #f0f0f0;
    border: 1px solid #ccc;
    width: 70vw;
    min-height: 150vh;
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.left-panel {
    border-right: 1px solid #ccc;
    background: #fff;
    border-top: 0;
    width: 72%;
    min-height: 90vh;
    overflow: hidden;
}

.right-panel {
    width: 28%;
    background: #f0f0f0;
    display: flex;
    justify-content: center;
}

.news {
    border-bottom: 1px dashed #ccc;
    padding: 0 20px;
    font-size: 18px;
    height: auto;
    cursor: grab;
}

.new-head {
    padding: 5px 0;
}

.hot {
    border: 1px solid #dfdfdf;
    background-color: #f9f9f9;
    width: 85%;
    height: 50vh;
    display: inline-block;
    margin-top: 2vh;
}

.hot-title {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 700;
    padding-left: 15px;
    background-color: #ececec;
    margin: 0 !important;
}

.hot-item {
    height: 5vh;
    line-height: 32px;
    font-size: 13px;
    border-top: 1px solid #dcdcdc;
    padding: 2px 14px 2px 6px;
    display: flex;
    align-items: center;
    cursor: grab;
}

.num {
    font: 12px/18px georgia;
    width: 44px;
    text-align: center;
}

.hot-text {
    color: #000;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>