<template>
    <div class="all">
        <div style="position: absolute;left: 7%;top:2%;display: flex;align-items: center;cursor: grab;" @click="router.back()"><el-icon><ArrowLeftBold /></el-icon>返回</div>
        <div v-if="isRegist==1" style="position: absolute;width: 40vw;height: 10vh;background-color: white;color: black;z-index: 9999;display: flex;justify-content: center;align-items: center;">注册成功，页面将在{{ backLoginTime }}秒后重返登录页</div>
        <div class="loginform">
            <div style="height: 8vh;width: 100%;display: flex;justify-content: center;">
                <h1>Welcome</h1>
            </div>
            <div style="height: 10vh;">
                <el-tabs @tab-change="change_tab" v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                    <el-tab-pane class="tab-1" label="快捷登录" name="first">
                        <div class="username"><input placeholder="请输入您的手机号码" v-model="phoneNumber_"></div>
                        <div class="password"><input type="password" placeholder="请输入您的密码" v-model="password_"></div>
                        <div v-if="isWrong==true" style="color: red;font-size: 14px;">用户名或密码错误</div>
                        <div class="username">
                            <CaptchaComp @success="finish_vaid" v-if="tab_type == 0" ref="child"></CaptchaComp>
                        </div>
                        <div class="check"><input v-model="agree" type="checkbox"
                                style="margin-left: 7%;width: 15px;height: 15px;">已阅读并同意<a style="text-decoration: none;color: aqua;cursor: grab;" @click="router.push('/noticeb')">用户协议</a>、<a style="text-decoration: none;color: aqua;cursor: grab;" @click="router.push('/notice')">隐私协议</a><div
                                style="display: inline-block;position: absolute;right: 2vw;">忘记密码</div>
                        </div>
                        <div id="login" style="width: 100%;display: flex;justify-content: center;margin-top: 1vh;">
                            <el-button @click="login" size="large" type="primary">登录</el-button>
                        </div>
                        <el-divider>
                            其它登录方式
                        </el-divider>
                        <div style="width: 100%;display: flex;justify-content: center;">
                            <div style="width: 60%;display: flex;justify-content: space-between;">
                                <div><img width="40px" height="40px" src="@/assets/weixin_login.png"></div>
                                <div><img width="40px" height="40px" src="@/assets/qq_login.png"></div>
                                <div><img width="40px" height="40px" src="@/assets/weibo_login.png"></div>
                                <div><img width="40px" height="40px" src="@/assets/zhifu_login.png"></div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="亚信用户注册" name="second">
                        <div class="username"><input placeholder="请输入您的手机号码" v-model="phoneNumber"></div>
                        <div class="username"><input type="password" placeholder="请输入您要设置的密码" v-model="password">
                        </div>
                        <div class="username">
                            <CaptchaComp @success="finish_vaid" v-if="tab_type == 1" ref="child"></CaptchaComp>
                        </div>
                        <div class="phonenumber">
                            <input v-model="code" placeholder="请输入验证码">
                            <el-button @click="sendSms" v-if="isSend == false" size="large"
                                type="defaut">获取验证码</el-button>
                            <div style="position: absolute;right: 5vw;" v-else-if="isSend == true">{{ sendTime }}<span>秒后重发</span></div>
                        </div>
                        <div v-if="isRegist==2" style="color: red;">注册失败,请更换手机号码后重试</div>
                        <div id="login" style="width: 100%;display: flex;justify-content: center;margin-top: 5vh;">
                            <el-button @click="regist" size="large" type="primary">注册</el-button>
                        </div>

                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<style scoped>
.all {
    width: 100vw;
    height: 100vh;
    background-image: url('@/assets/login.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.loginform {
    width: 35vw;
    height: 60vh;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    font-family: poppins;
    color: white;
    overflow: hidden;
    padding-left: 70px;
    padding-right: 70px;
}

:deep(.el-tabs__item) {
    color: white;
    /* 未选中状态的文本颜色 */
}

.username,
.password {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.phonenumber {
    width: 100%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding-left: 4%;
    padding-right: 4%;
    position: relative;
    display: flex;
    align-items: center;
}

.captcha {
    width: 100%;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.username input,
.password input {
    width: 80%;
    height: 4vh;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 5px 25px;
    font-size: 15px;
}

.phonenumber input {
    width: 50%;
    height: 4vh;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 5px 25px;
    font-size: 15px;
    margin-left: 2%;
    margin-right: 12%;
}

.captcha input {
    width: 40%;
    height: 4vh;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 5px 25px;
    font-size: 15px;
    margin-left: 5.5%;
}

.check {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

:deep(el-divider__text is-center) {
    background-color: rgba(red, green, blue, 0);
}
</style>

<script setup>
import { defineComponent, onMounted, ref } from 'vue'
import CaptchaComp from '@/components/CaptchaComp.vue';
import { getPhoneCode,regist_,login_ } from '@/api/login';
import router from '@/router';
import { result } from 'lodash';
defineComponent({ components: [CaptchaComp] })
const activeName = ref('first');
const child = ref(null)
const change_tab = (name) => {
    isVaid.value = false;
    if (name == 'first') {
        tab_type.value = 0
    }
    else if (name == 'second') {
        tab_type.value = 1
    }
}
const sendSms = () => {
    if(!isVaid.value){
        alert('请完成滑动验证')
        return
    }
    isSend.value = true
    getPhoneCode(phoneNumber.value)
    const intervalId = setInterval(periodicTask, 1000);

    // 设置一个计数器，用于记录执行次数
    let count = 0;

    // 每次执行任务时，计数器加1
    function periodicTask() {
        console.log(sendTime);
        // 在这里编写要周期执行的代码逻辑

        // 计数器加1
        sendTime.value -= 1;
        count++;

        // 如果执行次数达到60次，停止定时器
        if (count === 60) {
            clearInterval(intervalId);
            isSend.value = false;
            sendTime.value = 60;
            console.log("定时器停止，任务执行完毕");
        }
    }

    // 启动定时器

}

const finish_vaid = () => {
    isVaid.value = true
}

const login = async() => {
    if(!isVaid.value){
        alert('请完成滑动验证')
        return
    }
    if(!agree.value){
        alert('请阅读并同意用户协议与隐私协议')
        return
    }
    const result = await login_(phoneNumber_.value,password_.value)
    console.log('res',result)
    if(result==true){
        router.push('/')
    }
    else{
        isWrong.value = true
    }
}

const regist = async() => {
    if(!isVaid.value){
        alert('请完成滑动验证')
        return
    }
    const result = await regist_(phoneNumber.value,password.value,code.value)
    if(result){
        isRegist.value = 1;
        const timeInterval = setInterval(()=>{
            if(backLoginTime.value<=0){
                clearInterval(timeInterval);
                location.reload();
                return
            }
            backLoginTime.value -= 1
        },1000);
    }
    else{
        isRegist.value = 2;
    }
}

let phoneNumber = ref('');
let password = ref('');
let phoneNumber_ = ref('');
let password_ = ref('');
let tab_type = ref(0);
let isSend = ref(false);
let isVaid = ref(false);
let sendTime = ref(60);
let code = ref('');
let agree = ref(false);
let isWrong = ref(false);
let isRegist = ref(0);
let backLoginTime = ref(3);

</script>