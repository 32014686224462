import http from '@/utils/request'
import axios from 'axios';
import { fa } from 'element-plus/es/locale';

export const captchaVerifyCallback = (captchaVerifyParam: string) => {
    let result = false;
    const data = { 'captchaVerifyParam': captchaVerifyParam }
    return new Promise((resolve) => {
        http.post(`/aliyunvaid`, data).then((res) => {
            result = res.data.result
            console.log(result)
            resolve({ captchaResult: result, bizResult: true })
        }).catch((error) => {
            console.log(error)
            resolve({ captchaResult: false, bizResult: true })
        })
    })
}

export const getPhoneCode = (phoneNumber: string) => {
    http.get(`/phonecode/${phoneNumber}`).then((res) => {
        console.log(res);
    }).catch((error) => {
        console.log(error);
    })
}

export const regist_ = (username: string, password: string, code: string) => {
    const data = { 'username': username, 'password': password, 'code': code }
    return new Promise((resolve)=>{
        http.post('/regist', data).then((res) => {
            if(res.data.code==200){
                resolve(true);
            }
            else{
                resolve(false);
            }
        }).catch((error) => {
            resolve(false)
        })
    })
}

export const login_ = async (username: string, password: string) => {
    const data = { 'username': username, 'password': password }
    return new Promise((resolve) => {
        http.post('/login', data).then((res) => {
            if (res.data.code == 200) {
                console.log(res)
                const token = res.data.token
                localStorage.setItem('token_', token)
                resolve(true)
            }
            else {
                resolve(false)
            }
        })
    })
}

export const getLoginMsg = (token: string) => {
    return new Promise((resolve) => {
        http.post('/loginmsg', {'token': token }).then((res) => {
            if (res.data.code == 200) {
                const reslut = { 'username': res.data.user_id, 'name': res.data.name }
                resolve(reslut)
            }
            else{
                resolve(false)
            }
        }).catch((error) => {
            console.log(error)
            resolve(false)
        })
    })
}

export const getMainNews = async(category_id: number,page: number) => {
    let result: never[] = []
    await http.get(`/articleData/${category_id}/${page}?enum=1`).then((res) => {
        result = res.data.data
    })
    return result
}

export const getArtTotal = async(category_id: number) => {
    let num = 0
    await http.get(`/articleTotal/${category_id}`).then((res)=>{
        num = res.data.data
    })
    return num
}

export const getLeader = async() => {
    let data: never[] = []
    await http.get('/leaderData').then((res)=>{
        data = res.data.data
    })
    return data
}

export const getHot = async() => {
    let data: never[] = []
    await http.get('/hot').then((res)=>{
        data = res.data.data
    })
    return data
}

export const search = async(keyword: string) => {
    let data: never[] = []
    await http.get(`/search?keyword=${keyword}`).then((res)=>{
        data = res.data.data
    })
    return data
}