<template>
    <div style="width: 100vw;height: auto;display: flex;justify-content: center;">
        <div class="container">
            <div style="display: flex;justify-content: center;width: 100%;"><h1>用户协议</h1></div>
            <div style="width: 100%;display: flex;justify-content: center;">
                <h4>协议最新更新于2025年1月15日</h4>
            </div>
            <p>尊敬的用户：</p>
            <p>欢迎您使用玉溪亚信税务师事务所有限公司（以下简称“本公司”）官网提供的服务。在您开始使用本公司的服务之前，请您仔细阅读并同意以下用户协议。本协议是您与本公司之间关于使用官网服务的法律协议，您使用官网服务的行为将被视为接受本协议的所有条款。
            </p>

            <div class="section">
                <h2>一、服务内容</h2>
                <ol>
                    <li>本公司官网提供包括但不限于税务咨询、税务筹划、税务申报代理等税务相关服务信息展示。</li>
                    <li>用户可以通过官网了解本公司的服务项目、服务流程、收费标准等信息。</li>
                    <li>用户可以通过官网在线提交服务需求，本公司将根据用户提交的信息与用户联系，进一步沟通服务细节。</li>
                </ol>
            </div>

            <div class="section">
                <h2>二、用户注册与账号管理</h2>
                <ol>
                    <li>用户在使用官网部分服务时，需要进行注册并创建账号。用户应保证注册时提供的信息真实、准确、完整。</li>
                    <li>用户应妥善保管自己的账号和密码，不得将账号和密码透露给他人。因用户自身原因导致账号和密码泄露，本公司不承担任何责任。</li>
                    <li>用户发现账号异常或被他人盗用时，应立即通知本公司，并按照本公司要求提供相关证明材料，以便本公司协助处理。</li>
                </ol>
            </div>

            <div class="section">
                <h2>三、用户权利与义务</h2>
                <ol>
                    <li>用户有权按照本协议的规定使用官网提供的服务。</li>
                    <li>用户应遵守国家法律法规，不得利用官网从事任何违法、违规活动。</li>
                    <li>用户应尊重本公司的知识产权，不得复制、修改、传播官网上的任何内容，未经本公司书面许可，不得将官网内容用于商业用途。</li>
                    <li>用户应如实提供自己的信息，不得提供虚假信息或冒用他人信息。</li>
                </ol>
            </div>

            <div class="section">
                <h2>四、本公司权利与义务</h2>
                <ol>
                    <li>本公司有权根据实际情况调整官网服务内容、服务方式、收费标准等，并在官网上进行公示。</li>
                    <li>本公司有权对用户提交的信息进行审核，如发现用户提交的信息存在虚假、违法、违规等情况，本公司有权拒绝提供服务，并保留追究用户法律责任的权利。</li>
                    <li>本公司应保证官网的正常运行，如因本公司原因导致官网无法正常使用，本公司将及时进行修复。</li>
                    <li>本公司应保护用户的个人信息安全，未经用户同意，不得将用户的个人信息泄露给第三方。</li>
                </ol>
            </div>

            <div class="section">
                <h2>五、知识产权</h2>
                <ol>
                    <li>本公司官网上的所有内容，包括但不限于文字、图片、音频、视频等，均受国家法律法规保护，本公司拥有相关的知识产权。</li>
                    <li>用户在使用官网过程中产生的任何内容，包括但不限于咨询问题、服务评价等，本公司有权进行收集、整理、使用，但本公司将尊重用户的隐私权，不会泄露用户的个人信息。</li>
                </ol>
            </div>

            <div class="section">
                <h2>六、免责声明</h2>
                <ol>
                    <li>本公司官网提供的服务信息仅供参考，用户应根据自己的实际情况谨慎选择服务。本公司不保证服务一定能满足用户的需求，也不保证服务的及时性、准确性、可靠性。</li>
                    <li>用户在使用官网过程中，因自身原因导致的任何损失，本公司不承担任何责任。</li>
                    <li>因不可抗力因素导致官网无法正常使用或服务无法提供，本公司不承担任何责任。</li>
                </ol>
            </div>

            <div class="section">
                <h2>七、协议的变更与终止</h2>
                <ol>
                    <li>本公司有权根据实际情况对本协议进行修改和补充，并在官网上进行公示。用户继续使用官网服务的行为，将被视为接受修改后的协议。</li>
                    <li>用户如违反本协议的规定，本公司有权终止用户使用官网服务的资格，并保留追究用户法律责任的权利。</li>
                </ol>
            </div>

            <div class="section">
                <h2>八、争议解决</h2>
                <p>本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向本公司所在地人民法院提起诉讼。</p>
            </div>

            <div class="footer">
                <p>本协议自用户同意并使用本公司官网服务之日起生效。</p>
                <p>玉溪亚信税务师事务所有限公司(联系电话：2060729)</p>
                <p>[2025年1月15日]</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .container {
            background: #fff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            width: 45vw;
        }
        h1, h2, h3 {
            color: #333;
        }
        p, ul, ol {
            color: #666;
        }
        .section {
            margin-bottom: 20px;
        }
        .section h2 {
            border-bottom: 2px solid #ddd;
            padding-bottom: 5px;
        }
        .section p, .section ul, .section ol {
            margin-top: 10px;
        }
        .section ol {
            list-style-type: decimal;
            padding-left: 20px;
        }
        .section ul {
            list-style-type: disc;
            padding-left: 20px;
        }
        .footer {
            text-align: center;
            margin-top: 30px;
            color: #777;
        }
</style>