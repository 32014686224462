<template>
    <div class="all">
        <SearchComp @close="onClose" v-if="search_type==1" style="z-index: 9999;position: fixed;top: 0;left: 0;"></SearchComp>
        <NavComp @search="onSearch" :isFixed="isFixed_" :isColored="isColored_" :icon_num="icon_num_" :isLogin="isLogin_" :name="name_"></NavComp>
        <div class="nav-image">
            <div class="image-text">
                <p style="margin-bottom: 10px;">精准税务筹划为企业创造</p>
                <p style="margin-top: 10px;text-indent: 3ch;">&nbsp;更大的价值</p>
                <div class="nav-more">了解更多</div>
            </div>
            <div style="width: 70vw;height: 1px;position: absolute;left: 15vw;bottom: 12vh;background-color: white;">
            </div>
            <div style="position: absolute;bottom: 6vh;font-weight: bold;left: 15vw;font-size: 28px;color: white;">最新动态
            </div>
        </div>
        <div class="news">
            <div @click="goArticle(0)" class="new-0" style="width: 63%;height: 48%;transition: box-shadow 0.3s ease;"><img width="100%"
                    height="50%" src="@/assets/bck0.png">
                <div style="height: 50%;width: 100%;position: relative;">
                    <div class="new-time" style="color: #0052D9;">{{ newsData[0].update_time.slice(0,10) }}</div>
                    <div style="position: absolute;top: 9vh;">
                        <div class="new-title">{{ newsData[0].title.slice(0,10) }}</div>
                        <div class="new-title1"><p>{{ extractTextFromHTML(newsData[0].content).slice(0,20) }}</p></div>
                    </div>
                </div>
            </div>
            <div @click="goArticle(1)" class="new-1" style="width: 34%;height: 48%;"><img width="100%" height="40%" src="@/assets/bck2.png">
                <div style="width: 100%;height: 60%;background-color: rgb(0, 169,206);position: relative;">
                    <div class="new-time1" style="color: white;">{{ newsData[1].update_time.slice(0,10) }}</div>
                    <div style="position: absolute;top: 9vh;color: white;">
                        <div class="new-title">{{ newsData[1].title.slice(0,10) }}</div>
                        <div class="new-title1"><p>{{ extractTextFromHTML(newsData[1].content).slice(0,20) }}</p></div>
                    </div>
                </div>
            </div>
            <div @click="goArticle(2)" class="new-2" style="width: 32%;height: 48%;"><img width="100%" height="40%" src="@/assets/bck4.png">
                <div style="width: 100%;height: 60%;background: #0052d9;position: relative;">
                    <div class="new-time1" style="color: white;">{{ newsData[2].update_time.slice(0,10) }}</div>
                    <div style="position: absolute;top: 9vh;color: white;">
                        <div class="new-title">{{ newsData[2].title.slice(0,10) }}</div>
                        <div class="new-title1"><p>{{ extractTextFromHTML(newsData[2].content).slice(0,20) }}</p></div>
                    </div>
                </div>
            </div>
            <div @click="goArticle(3)" class="new-3" style="width: 32%;height: 48%;"><img width="100%" height="40%" src="@/assets/bck3.png">
                <div style="width: 100%;height: 60%;background-color: white;position: relative;">
                    <div class="new-time" style="color: #0052D9;">{{ newsData[3].update_time.slice(0,10) }}</div>
                    <div style="position: absolute;top: 9vh;">
                        <div class="new-title">{{ newsData[3].title.slice(0,10) }}</div>
                        <div class="new-title1"><p>{{ extractTextFromHTML(newsData[3].content).slice(0,20) }}</p></div>
                    </div>
                </div>
            </div>
            <div @click="goArticle(4)" class="new-4" style="width: 32%;height: 48%;"><img width="100%" height="40%" src="@/assets/bck5.png">
                <div style="width: 100%;height: 60%;background-color: #eee;position: relative;">
                    <div class="new-time" style="color: #0052D9;">{{ newsData[4].update_time.slice(0,10) }}</div>
                    <div style="position: absolute;top: 9vh;">
                        <div class="new-title">{{ newsData[4].title.slice(0,10) }}</div>
                        <div class="new-title1"><p>{{ extractTextFromHTML(newsData[4].content).slice(0,20) }}</p></div>
                    </div>
                </div>
            </div>
        </div>
        <BottomNav></BottomNav>
    </div>
</template>
<script setup>
import NavComp from '@/components/NavComp.vue'
import BottomNav from '@/components/BottomNav.vue';
import SearchComp from '@/components/SearchComp.vue';
import { defineComponent } from 'vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { getLoginMsg } from '@/api/login';
import { getMainNews } from '@/api/login';
import router from '@/router';
defineComponent({
    components: { NavComp,BottomNav,SearchComp }
})
let isFixed_ = ref(false)
let isColored_ = ref(false)
let icon_num_ = ref(false)
let search_type = ref(0)
let isLogin_ = ref(false)
let name_ = ref('')
let newsData = ref([{'update_time':'2028.1.4','title':'税改新政解读与应对策略','content':'深入剖析最新税法修订要点，助力企业精准筹划税务布局'},{'update_time':'2028.1.4','title':'税务稽查案例分析与风险防范','content':'剖析典型税务稽查案例，提炼关键风险点，为企业筑牢税务合规防线'},{'update_time':'2028.1.4','title':'税收优惠政策精准匹配与申报指南','content':'全面梳理行业税收优惠政策，提供详细申报流程，助力企业尽享政策红利'},{'update_time':'2028.1.4','title':'国际税收动态与跨境税务筹划','content':'聚焦全球税收动态，解读跨境税务规则变化，为企业“走出去”保驾护航'},{'update_time':'2028.1.4','title':'税务数字化转型实践与创新','content':'探索税务数字化转型路径，分享前沿技术应用案例，推动企业税务管理升级'}])
onMounted(async() => {
    window.addEventListener('scroll', handleScroll);
    await getLoginMsg_()
    newsData.value = await getMainNews(1,1)
    localStorage.setItem('a1',JSON.stringify(newsData.value))
})
onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
})
const getLoginMsg_ = async() => {
    const token = localStorage.getItem('token')
    if(token){
        const loginMsg = await getLoginMsg(token)
        if(loginMsg!=false){
            isLogin_.value = true
            name_.value = loginMsg.name
        }
    }
}
const handleScroll = () => {
    if(search_type.value==1){
        search_type.value = 0;
    }
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    isFixed_.value = scrollTop > 0; // 当页面滚动时，导航栏固定
    isColored_.value = scrollTop > 150; // 当滚动超过150px时，导航栏变色
    if (isColored_.value == true) {
        icon_num_.value = true
    }
    else {
        icon_num_.value = false
    }
}
const onSearch = ()=>{
    search_type.value = 1;
}
const onClose = ()=> {
    search_type.value = 0;
}

const extractTextFromHTML = (htmlString)=>{
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
}

const goArticle = (index_) => {
    router.push({'name':'detail','query':{'category_id':1,'index':index_}})
}

</script>
<style scoped>
@font-face {
  font-family: 'TencentSans W7'; /* 自定义字体名称 */
  src: url('@/assets/font/腾讯体 W7.ttf') format('truetype'); /* 多种格式以确保兼容性 */
  font-weight: normal; /* 字体粗细 */
  font-style: normal; /* 字体样式 */
}
@keyframes appear{
    0% {
        transform: scale(0.1,0.1);
    }
    100% {
        transform: scale(1,1);
    }
}
.all {
    width: 100%;
    height: 300vh;
    /* overflow-y: scroll; */
    position: relative;
}

.nav-image {
    z-index: 1;
    width: 100vw;
    height: 78vh;
    position: absolute;
    top: 0;
    background-image: url('@/assets/bck0.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

.image-text {
    margin-left: 8vw;
    color: white;
    font-size: 50px;
    font-family: 微软雅黑;
}

.nav-more {
    width: 140px;
    height: 45px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    border-radius: 8px;
    margin-left: 10vw;
}

.news {
    width: 70vw;
    height: 150vh;
    position: absolute;
    left: 15vw;
    top: 74vh;
    display: flex;
    justify-content: space-between;
    align-self: start;
    flex-wrap: wrap;
    z-index: 999;
}
.new-2,.new-3,.new-4{
    animation: appear linear;
    animation-timeline: scroll(root);
    animation-range: entry 0% entry 45%;
}

.new-0:hover,
.new-1:hover,.new-2:hover,.new-3:hover,.new-4:hover {
    cursor: grab;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.new-time {
    position: relative;
    left: 0;
    top: 4vh;
    overflow: hidden;
    width: 8vw;
    height: 28px;
    border-top: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: 'TencentSans W7', sans-serif;
}

.new-time::before {
    content: "";
    display: block;
    position: absolute;
    left: calc(100% - 12px);
    bottom: 0px;
    width: 100%;
    border-top: 1px solid #0052D9;
    transform: rotate(-69deg) translate(-1px, 0px);
    transform-origin: 0px 0px;
}

.new-time::after {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: calc(100% - 12px);
    border-top: 1px solid #0052D9;
}

.new-time1 {
    position: relative;
    left: 0;
    top: 4vh;
    overflow: hidden;
    width: 8vw;
    height: 28px;
    border-top: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: 'TencentSans W7', sans-serif;
}

.new-time1::before {
    content: "";
    display: block;
    position: absolute;
    left: calc(100% - 12px);
    bottom: 0px;
    width: 100%;
    border-top: 1px solid white;
    transform: rotate(-69deg) translate(-1px, 0px);
    transform-origin: 0px 0px;
}

.new-time1::after {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: calc(100% - 12px);
    border-top: 1px solid white;
}
.new-title{
    font-size: 24px;
    line-height: 1.4em;
    max-height: 50px;
    margin-left: 2vw;
    margin-right: 2vw;
}
.new-title1{
    max-height: 75px;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 2vh;
}
</style>