<template>
    <div style="width: 100vw;height: auto;display: flex;justify-content: center;">
        <div class="container">
            <div style="width: 100%;display: flex;justify-content: center;">
                <h1>隐私协议</h1>
            </div>
            <div style="width: 100%;display: flex;justify-content: center;">
                <h4>协议最新更新于2025年1月15日</h4>
            </div>
            <p>尊敬的用户：</p>
            <p>玉溪亚信税务师事务所有限公司（以下简称“本公司”）深知用户隐私的重要性，特此制定本隐私协议，旨在明确本公司在官网运营过程中对用户个人信息的收集、使用、保护等方面的原则和措施。本隐私协议是本公司与用户之间关于个人信息保护的法律文件，用户使用本公司官网服务的行为将被视为接受本隐私协议的所有条款。
            </p>

            <div class="section">
                <h2>一、信息收集</h2>
                <ol>
                    <li><strong>注册信息</strong>：当用户注册本公司官网账号时，本公司将收集用户的姓名、电子邮箱、手机号码等基本信息，以便为用户提供个性化的服务。</li>
                    <li><strong>服务需求信息</strong>：用户在提交服务需求时，本公司将收集与服务需求相关的信息，如企业名称、税务问题描述、联系方式等，以便本公司更好地了解用户需求并提供相应的服务。
                    </li>
                    <li><strong>浏览行为信息</strong>：本公司将通过技术手段收集用户的浏览行为信息，包括但不限于访问时间、访问页面、停留时间等，以便优化官网内容和提升用户体验。</li>
                </ol>
            </div>

            <div class="section">
                <h2>二、信息使用</h2>
                <ol>
                    <li><strong>提供服务</strong>：本公司将使用收集到的用户信息，为用户提供税务咨询、税务筹划、税务申报代理等服务。</li>
                    <li><strong>改进服务</strong>：本公司将分析用户的浏览行为信息，了解用户的需求和偏好，以便不断改进官网内容和服务质量。</li>
                    <li><strong>发送通知</strong>：本公司将使用用户的联系方式，向用户发送服务相关的通知、提醒等信息，如服务进度更新、重要政策变动等。</li>
                    <li><strong>数据分析</strong>：本公司将对收集到的用户信息进行统计分析，以便更好地了解用户群体的特点和需求，为本公司的发展战略提供数据支持。</li>
                </ol>
            </div>

            <div class="section">
                <h2>三、信息保护</h2>
                <ol>
                    <li><strong>数据安全措施</strong>：本公司将采取先进的技术手段和严格的安全管理措施，确保用户信息的安全。包括但不限于数据加密存储、访问权限控制、防火墙设置等，防止用户信息被泄露、篡改或损坏。
                    </li>
                    <li><strong>内部管理</strong>：本公司将严格限制员工对用户信息的访问权限，只有经过授权的员工才能访问用户信息，并且这些员工必须遵守本公司的保密政策。</li>
                    <li><strong>第三方合作</strong>：本公司在与第三方合作时，将严格要求第三方遵守本隐私协议的规定，确保用户信息的安全。未经用户同意，本公司不会将用户信息提供给第三方用于商业用途。
                    </li>
                </ol>
            </div>

            <div class="section">
                <h2>四、信息共享</h2>
                <ol>
                    <li><strong>法定情形</strong>：在法律法规允许的范围内，本公司可能会根据司法机关、行政机关等有权机关的要求，披露用户信息。</li>
                    <li><strong>用户同意</strong>：在得到用户明确同意的情况下，本公司可能会将用户信息共享给第三方，如用户委托本公司与第三方进行合作时。</li>
                </ol>
            </div>

            <div class="section">
                <h2>五、用户权利</h2>
                <ol>
                    <li><strong>访问与更正</strong>：用户有权随时访问本公司官网，查看自己的个人信息，并在必要时要求本公司对错误或过时的信息进行更正。</li>
                    <li><strong>删除信息</strong>：用户有权要求本公司删除其个人信息，但本公司可能会根据法律法规的要求或业务需要，在一定期限内保留部分必要的信息。</li>
                    <li><strong>撤回同意</strong>：用户有权随时撤回对本公司收集、使用其个人信息的同意。撤回同意后，本公司将停止对用户信息的相应处理，但不影响此前基于用户同意已经完成的信息处理活动。
                    </li>
                </ol>
            </div>

            <div class="section">
                <h2>六、未成年人信息保护</h2>
                <p>本公司非常重视未成年人的个人信息保护。如果用户是未成年人，请在使用本公司官网服务前，确保已经得到监护人的同意，并在监护人的指导下使用服务。本公司将采取合理的措施，确保未成年人的个人信息得到妥善保护。
                </p>
            </div>

            <div class="section">
                <h2>七、隐私协议的变更</h2>
                <p>本公司有权根据实际情况对本隐私协议进行修改和补充，并在官网上进行公示。用户继续使用官网服务的行为，将被视为接受修改后的隐私协议。</p>
            </div>

            <div class="section">
                <h2>八、争议解决</h2>
                <p>本隐私协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。如双方就本隐私协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向本公司所在地人民法院提起诉讼。</p>
            </div>

            <div class="footer">
                <p>本隐私协议自用户同意并使用本公司官网服务之日起生效。</p>
                <p>玉溪亚信税务师事务所有限公司(联系电话：2060729)</p>
                <p>[2025年1月15日]</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.container {
    width: 45vw;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1,
h2,
h3 {
    color: #333;
}

p,
ul,
ol {
    color: #666;
}

.section {
    margin-bottom: 20px;
}

.section h2 {
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
}

.section p,
.section ul,
.section ol {
    margin-top: 10px;
}

.section ol {
    list-style-type: decimal;
    padding-left: 20px;
}

.section ul {
    list-style-type: disc;
    padding-left: 20px;
}

.footer {
    text-align: center;
    margin-top: 30px;
    color: #777;
}
</style>